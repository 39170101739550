export class ApiKey {
  created_at?: string;

  expires_at?: string;

  id?: string;

  is_enabled?: boolean;

  name?: string;

  user?: {
    email: string;
    firstname: string;
    id: number;
    lastname: string;
  };

  constructor(init: Partial<ApiKey>) {
    Object.assign(this, init);
  }
}

export interface DecryptedApiKey {
  api_key: string;
}

export class ApiKeyView extends ApiKey {
  api_key?: string;
}
