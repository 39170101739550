import { AxiosRequestConfig } from 'axios';
import { User, isFullAdmin } from '@ax/data-services-user/models/user';
import { stringifyUrlQuery } from '@/utils/util';

import { DecryptedApiKey, ApiKeyView, ApiKey } from '@/models/api-keys';
import { PaginatedAPIData } from '@/types/utility-types';
import httpClient from './http-client';

const ORG_ENDPOINT = 'orgs';
const USER_ENDPOINT = 'users';
const API_KEY_ENDPOINT = 'api_keys';
const DECRYPT_ENDPOINT = 'decrypt';

function resourceBaseURL(
  userId: string | number,
  orgId: string | number,
  keyId?: string,
): string {
  const base = `/${USER_ENDPOINT}/${userId}/${API_KEY_ENDPOINT}`;
  const query = stringifyUrlQuery({ o: orgId });
  return keyId ? `${base}/${keyId}?${query}` : `${base}?${query}`;
}

export function decryptApiKey(
  userId: number,
  orgId: string,
  keyId: string,
): Promise<DecryptedApiKey> {
  const url = `/${USER_ENDPOINT}/${userId}/${API_KEY_ENDPOINT}/${keyId}/${DECRYPT_ENDPOINT}?${stringifyUrlQuery(
    {
      o: orgId,
    },
  )}`;
  return httpClient
    .post<DecryptedApiKey>(url, null, {
      loaderEnabled: false,
    } as AxiosRequestConfig)
    .then(({ data }) => data);
}

export function listApiKeys(
  user: User,
  orgId: string,
  query: string,
): Promise<[ApiKey[], number]> {
  const url = isFullAdmin(user, orgId)
    ? `/${ORG_ENDPOINT}/self/${API_KEY_ENDPOINT}?${query}`
    : `/${USER_ENDPOINT}/${user.id}/${API_KEY_ENDPOINT}?${query}`;

  return httpClient.get<PaginatedAPIData<ApiKey>>(url).then(({ data }) => {
    const { results, size } = data;
    return [results, size];
  });
}

export function updateApiKey(orgId: string, apiKey: ApiKeyView, loader = true) {
  const stripped = new ApiKey(apiKey);
  return httpClient
    .put(resourceBaseURL(apiKey.user!.id, orgId, stripped.id), stripped, {
      loaderEnabled: loader,
    })
    .then(({ data }) => data);
}

export function createApiKey(
  userId: number,
  orgId: string | number,
  apiKey: ApiKey,
): Promise<ApiKeyView> {
  return httpClient
    .post(resourceBaseURL(userId, orgId), apiKey)
    .then(({ data }) => data);
}

export function deleteApiKey(
  orgId: string | number,
  apiKey: ApiKey,
): Promise<void> {
  return httpClient.delete(resourceBaseURL(apiKey.user!.id, orgId, apiKey.id));
}
